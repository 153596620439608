import React from "react";
import PropTypes from "prop-types";

import { getDisplayName } from "@utils/component";

export const INITIAL = Symbol("form-initial");
export const PENDING = Symbol("form-pending");
export const SUCCESS = Symbol("form-success");
export const ERROR = Symbol("form-error");

function withForm(WrappedComponent) {
  class WithForm extends React.Component {
    constructor(props) {
      super(props);

      this.submitInput = this.submitInput.bind(this);

      this.state = {
        status: INITIAL,
      };
    }

    setFormValue(setter) {
      return (e) => setter(e.target.value);
    }

    async submitInput(input) {
      if (this.state.status === PENDING) {
        return;
      }

      this.setState({ status: PENDING });

      try {
        const response = await this.props.onSubmit(input);

        this.setState({ status: SUCCESS });
        this.props.onSuccess(response);
      } catch (error) {
        this.setState({ status: ERROR });
        this.props.onError({ error });
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { onSubmit, onSuccess, onError, ...passThroughProps } = this.props;

      return (
        <WrappedComponent
          status={this.state.status}
          isFormDisabled={this.state.status === PENDING}
          setFormValue={this.setFormValue}
          submitInput={this.submitInput}
          {...passThroughProps}
        />
      );
    }
  }

  WithForm.displayName = `WithForm(${getDisplayName(WrappedComponent)})`;
  WithForm.defaultProps = {
    onSuccess: () => {},
    onError: () => {},
  };
  WithForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
  };

  return WithForm;
}

export default withForm;
