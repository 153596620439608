import React from "react";
import PropTypes from "prop-types";

const Loader = ({ text = "" }) => {
  return (
    <span className="loader">
      {text && <span className="text">{text}</span>}
      <span className="indicator">.</span>
      <span className="indicator">.</span>
      <span className="indicator">.</span>
    </span>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
