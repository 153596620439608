import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";

import Loader from "@components/Loader";
import withForm, { PENDING, SUCCESS } from "@components/withForm";

const isEmailRegEx = /.+@.+/;

const ContactForm = ({
  status,
  isFormDisabled,
  setFormValue,
  submitInput,
  recaptchaSiteKey,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = useRef(null);

  const validateInput = () => {
    // validate inputs (should be already handled by HTML validation)
    if (message.trim().length === 0 || !recaptcha) {
      return false;
    } else if (!isEmailRegEx.test(email)) {
      return false;
    }

    return true;
  };
  const clearInput = () => {
    setEmail("");
    setMessage("");
    recaptchaRef.current.reset();
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateInput()) {
      return;
    }

    await submitInput({
      email,
      message,
      recaptcha,
    });
  };

  useEffect(() => {
    if (status === SUCCESS) {
      clearInput();
    }
  }, [status]);

  return (
    <form onSubmit={sendMessage}>
      <input
        type="email"
        placeholder={t("contact.form.email")}
        value={email}
        disabled={isFormDisabled}
        onChange={setFormValue(setEmail)}
        required
      />
      <br />
      <textarea
        rows="6"
        cols="26"
        placeholder={t("contact.form.message")}
        value={message}
        disabled={isFormDisabled}
        onChange={setFormValue(setMessage)}
        required
      ></textarea>
      <ReCAPTCHA
        sitekey={recaptchaSiteKey}
        ref={recaptchaRef}
        onChange={setRecaptcha}
      />
      <button className="primary-btn" disabled={isFormDisabled}>
        {status === PENDING ? (
          <Loader text={t("contact.form.sending")} />
        ) : (
          t("contact.form.send")
        )}
      </button>
    </form>
  );
};

ContactForm.propTypes = {
  status: PropTypes.symbol.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  setFormValue: PropTypes.func.isRequired,
  submitInput: PropTypes.func.isRequired,
  recaptchaSiteKey: PropTypes.string.isRequired,
};

export default withForm(ContactForm);
