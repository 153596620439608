import React from "react";
import PropTypes from "prop-types";

import CloseButton from "@components/CloseButton";

export const SUCCESS = "message-success";
export const ERROR = "message-error";

const Message = ({ type, children, close }) => {
  let className = "message";

  switch (type) {
    case SUCCESS:
      className += " success";
      break;

    case ERROR:
      className += " error";
      break;

    default:
      className += " info";
  }

  return (
    <div className={className}>
      {children}
      <CloseButton onClick={close} />
    </div>
  );
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  type: PropTypes.oneOf([SUCCESS, ERROR]),
};

export default Message;
