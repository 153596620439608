import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { useScrollTop } from "@hooks";

import Layout from "@components/Layout";
import Header from "@components/Header";
import SEO from "@components/SEO";
import PageHeading from "@components/PageHeading";
import Message, { ERROR, SUCCESS } from "@components/Message";
import ContactForm from "@components/ContactForm";

import { sendContactForm } from "@services";

const ContactPage = ({ data, location }) => {
  const { contactEmail, recaptchaSiteKey } = data.site.siteMetadata;
  const { t } = useTranslation();
  const [message, setMessage] = useState({ type: null, text: "" });

  const showSuccessMessage = () => {
    setMessage({ type: SUCCESS, text: t("contact.form.success") });
  };
  const showErrorMessage = () => {
    setMessage({ type: ERROR, text: t("contact.form.error") });
  };
  const removeMessage = () => {
    setMessage({ type: null, text: "" });
  };

  const handleContactFormSubmit = ({ email, message, recaptcha }) => {
    removeMessage();
    return sendContactForm({ email, message, recaptcha });
  };

  useScrollTop(message.type !== null);

  return (
    <Layout>
      <SEO
        title={t("contact.title")}
        description={t("contact.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        {message.type && (
          <Message type={message.type} close={removeMessage}>
            {message.text}
          </Message>
        )}
        <div className="main-content-wrapper">
          <PageHeading>{t("contact.heading")}</PageHeading>
          <div className="contact-info">
            <p className="email">
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </p>
            <p>{t("contact.or")}</p>
            <ContactForm
              recaptchaSiteKey={recaptchaSiteKey}
              onSubmit={handleContactFormSubmit}
              onSuccess={showSuccessMessage}
              onError={showErrorMessage}
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

ContactPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const query = graphql`
  query ContactPage {
    site {
      siteMetadata {
        contactEmail
        recaptchaSiteKey
      }
    }
  }
`;
