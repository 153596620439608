export const sendContactForm = async ({ email, message, recaptcha }) => {
  try {
    const res = await fetch("https://api.mrviktor.com/handle-contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, message, recaptcha }),
    });

    const body = await res.json();

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    if (body.error) {
      throw new Error(body.error);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
